import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Carousel from '../UI/Carousel/Carousel';
import { SpinnerIcon } from '../UI/OverlaySpinner/OverlaySpinner';

import styles from './Dashboard.module.scss';

import { campaignStatus } from '../../shared/campaignStatus';
import { formatDate } from '../../shared/dateTime';

import CampaignService from '../../api/Campaigns';

const DashboardCampaignSection = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [campaignStats, setCampaignStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const cardRefs = useRef([]);
  const [cardHeight, setCardHeight] = useState(0);

  const { isAdvertisingPartner } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const getCampaigns = async () => {
    try {
      const result = await CampaignService.list({
        limit: 12,
        page: 1,
        order: 'endDate',
        order_direction: 'ASC',
        status: campaignStatus.STATE_ACTIVE,
        fallback: false,
        stats: true,
        countData: true,
      });

      setCampaigns(result.data.data);
      setCampaignStats(result.data.stats);
      setLoading(false);
    } catch (err) {
      process.env.NODE_ENV !== 'production' && console.error(err);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  useEffect(() => {
    const getTallestSlideHeight = () => {
      if (cardRefs.current[0]) {
        setCardHeight(
          Math.max(...cardRefs.current.map((slide) => slide.scrollHeight)),
        );
      }
    };

    window.addEventListener('resize', getTallestSlideHeight);

    return () => {
      window.removeEventListener('resize', getTallestSlideHeight);
    };
  }, [cardRefs]);

  if (error) {
    return (
      <div
        className={`alert alert-danger mb-0 h-100 d-flex justify-content-center align-items-center ${styles.dashboardContainer}`}
        role="alert"
      >
        Unable to load campaigns
      </div>
    );
  }

  if (loading) {
    return (
      <div
        className={`d-flex justify-content-center align-items-center ${styles.dashboardContainer}`}
      >
        <SpinnerIcon />
      </div>
    );
  }

  return (
    <div className={`${styles.dashboardContainer}`}>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2 className="mb-0">Live Campaigns</h2>
        <button
          onClick={() => navigate('/campaigns')}
          className={styles.chevronRightButton}
        >
          <span className="me-3">See All</span>
          <div>
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.15356 1L5.14391 5L1.15356 9"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>
      </div>

      {campaignStats && (
        <div className={styles.campaignStats}>
          <p className="mb-0 extra-small">
            {campaignStats.totalCampaigns} Live Campaigns
          </p>
          <span className="separator" />
          <p className="d-flex justify-content-center align-items-center extra-small ">
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="me-2"
            >
              <path
                d="M7.45475 1.18989L0.967186 12.4289C0.820607 12.6821 1.00336 13 1.29651 13H14.2736C14.5667 13 14.7495 12.6821 14.6029 12.4289L8.11531 1.18989C7.96873 0.936704 7.60324 0.936704 7.45666 1.18989H7.45475Z"
                stroke="#F20519"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.78491 4.43475V8.51233"
                stroke="#F20519"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.78496 11.3043C8.2592 11.3043 8.64365 10.9198 8.64365 10.4456C8.64365 9.97133 8.2592 9.58688 7.78496 9.58688C7.31072 9.58688 6.92627 9.97133 6.92627 10.4456C6.92627 10.9198 7.31072 11.3043 7.78496 11.3043Z"
                fill="#F20519"
              />
            </svg>
            <span className={styles.highlightText}>
              {campaignStats.campaignsEndingSoon} Ending Soon
            </span>
          </p>
          <span className="separator" />
          {!isAdvertisingPartner && (
            <p className="extra-small">
              {campaignStats.linkedProviders} Active Offers
            </p>
          )}
        </div>
      )}

      <div>
        <Carousel
          slidesPerGroup={3}
          slidesPerView={3}
          slideMinHeight={cardHeight < 200 ? 200 : cardHeight}
        >
          {campaigns.map((campaign, idx) => (
            <div
              key={campaign.id}
              className={styles.campaignCard}
              onClick={() => navigate(`/campaigns/edit/${campaign.id}`)}
              ref={(ref) => {
                cardRefs.current[idx] = ref;
              }}
            >
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h2 className={styles.cardHeader}>{campaign.name}</h2>
                <button className={styles.chevronRightButtonSecondary} aria-label="View campaign details">
                  <div>
                    <svg
                      width="5"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.15356 1L5.14391 5L1.15356 9"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </button>
              </div>

              <p className="mb-2 extra-small">Offer: {campaign.providerName}</p>

              <p
                className={`mb-2 extra-small ${
                  campaign.isEndingSoon ? styles.highlightText : ''
                }`}
              >
                {campaign.isEndingSoon ? 'Ending Soon' : 'End Date'}:{' '}
                {formatDate(campaign.endDate, 'dd/MM/yyyy')}
              </p>

              <div className="d-flex mb-2">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-2"
                >
                  <rect width="31.9228" height="32" rx="4" fill="#FF6F00" />
                  <path
                    d="M9.61953 17.6029C10.514 17.6029 11.2391 16.8778 11.2391 15.9833C11.2391 15.0889 10.514 14.3638 9.61953 14.3638C8.72509 14.3638 8 15.0889 8 15.9833C8 16.8778 8.72509 17.6029 9.61953 17.6029Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M17.2123 17.6029C18.1067 17.6029 18.8318 16.8778 18.8318 15.9833C18.8318 15.0889 18.1067 14.3638 17.2123 14.3638C16.3179 14.3638 15.5928 15.0889 15.5928 15.9833C15.5928 16.8778 16.3179 17.6029 17.2123 17.6029Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M22.3805 11.2391C23.275 11.2391 24 10.514 24 9.61953C24 8.72509 23.275 8 22.3805 8C21.4861 8 20.761 8.72509 20.761 9.61953C20.761 10.514 21.4861 11.2391 22.3805 11.2391Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M22.3805 24C23.275 24 24 23.2749 24 22.3805C24 21.486 23.275 20.7609 22.3805 20.7609C21.4861 20.7609 20.761 21.486 20.761 22.3805C20.761 23.2749 21.4861 24 22.3805 24Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M18.3364 14.8211L21.3397 10.858"
                    stroke="white"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M18.2626 17.398L21.1706 21.3063"
                    stroke="white"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M15.5927 16H11.239"
                    stroke="white"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                </svg>

                <div className="flex-grow d-flex justify-content-between flex-wrap flex-gap">
                  <div>
                    <p className="mb-0 small">
                      {campaign.countData?.connections ?? '0'}
                    </p>
                    <p className="mb-0 extra-small">Connections</p>
                  </div>

                  <div className="d-flex align-items-start">
                    <div className={styles.connectionPill}>
                      {campaign.countData?.connectionsToday
                        ? `${campaign.countData.connectionsToday}+ Today`
                        : `0 Today`}
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-2"
                >
                  <rect width="31.9228" height="32" rx="4" fill="#FF6F00" />
                  <path
                    d="M21.9968 13.1927C21.9968 11.6424 20.6535 10.3853 18.9968 10.3853H13C11.3433 10.3853 10 11.6424 10 13.1927C10 14.743 11.3433 16 13 16H19C20.6567 16 22 17.257 22 18.8073C22 20.3577 20.6567 21.6147 19 21.6147H13C11.3433 21.6147 10 20.3577 10 18.8073"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 10.3853V8"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 24V21.6147"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <div>
                  <p className="mb-0 small">
                    {(Number(campaign.cpdcCost) || 0) *
                      (Number(campaign.countData?.cpdcs) || 0)}{' '}
                    / {campaign.budget}
                  </p>
                  <p className="mb-0 extra-small">Budget Spent</p>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default DashboardCampaignSection;
