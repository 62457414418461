export const getCardValue = (key, campaigns, activeOnly) => {
  switch (key) {
    case 'Total Connections':
      return campaigns.reduce((total, campaign) => {
        if (activeOnly) {
          if (campaign.status === 1 || campaign.status === 10) {
            return total + campaign.countData.connections;
          }
          return total;
        }
        return total + campaign.countData.connections;
      }, 0);
    case 'Budget Spent':
      return `$ ${campaigns.reduce((total, campaign) => {
        if (activeOnly) {
          if (campaign.status === 1 || campaign.status === 10) {
            return (
              total +
              campaign.countData.connections * parseFloat(campaign.cpdcCost)
            );
          }
          return total;
        }

        return (
          total + campaign.countData.connections * parseFloat(campaign.cpdcCost)
        );
      }, 0)}`;
    case 'Live Campaigns':
      return campaigns.reduce((total, campaign) => {
        if (campaign.status === 1 || campaign.status === 10) {
          return total + 1;
        }

        return total;
      }, 0);

    default:
      return 0;
  }
};
