import React from 'react';
import Table from 'react-bootstrap/Table';

const ALPHA_MAX = 0.7;
const ALPHA_MIN = 0.1;
const color = (alpha) =>
  `rgba(255,111,0,${ALPHA_MIN + alpha * (ALPHA_MAX - ALPHA_MIN)})`;

const TableChart = ({ data, valueFormatter = (v) => v }) => {
  const maxValue = data.datasets.reduce(
    (acc, dataset) =>
      dataset.data.reduce((acc2, value) => {
        if (value > acc2) return value;
        return acc2;
      }, acc),
    0,
  );
  return (
    <Table className="mb-0" size="sm" striped bordered hover responsive>
      <thead>
        <tr>
          <th aria-label="Empty header for row labels"> </th>
          {data.datasets.map((dataset) => (
            <th key={dataset.label} className="text-right px-2">
              {dataset.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.labels.map((label, index) => (
          <tr key={label}>
            <td className="px-2 text-right">{label}</td>
            {data.datasets.map((dataset) => (
              <td
                key={dataset.label}
                className="text-right px-2"
                style={{
                  backgroundColor: color(dataset.data[index] / maxValue),
                }}
              >
                {valueFormatter(dataset.data[index])}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TableChart;
