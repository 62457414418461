import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Bootstrap Imports

import ProviderService from '../../api/Providers';
import ToasterBottom from '../UI/Toaster/ToasterBottom';

import * as actions from '../../store/actions/index';
import styles from './OfferDetail.module.scss';
import OfferHeader from './OfferHeader/OfferHeader';
import OfferAccordion from './OfferAccordion/OfferAccordion';
import OfferSectionGeneral from './OfferSections/OfferSectionGeneral';
import SettingsService from '../../api/Settings';
import AutomaticRedemptionApi from '../../api/AutomaticRedemption';
import StatisticsPanel from './StatisticsPanel/StatisticsPanel';
import OfferStatus from './OfferStatus/OfferStatus';
import OfferLandingPage from './OfferSections/OfferLandingPage';
import OfferConnectionPrice from './OfferSections/OfferConnectionPrice';
import OfferLocation from './OfferSections/OfferLocation';

const OfferDetail = ({ scrollTop }) => {
  const { providerId } = useParams();

  const [accordionActiveKey, setAccordionActiveKey] = useState('1');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [provider, setProvider] = useState(null);
  const [stats, setStats] = useState(null);
  const [costs, setCosts] = useState(null);
  const [costsHistory, setCostsHistory] = useState(null);
  const [toast, setToast] = useState({ show: false, header: '', class: '' });
  const [totalBalance, setTotalBalance] = useState(null);
  const [saveTick, setSaveTick] = useState(0);
  const [toastBottom, setToastBottom] = useState({
    show: false,
    success: false,
    body: '',
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [filePath, setFilePath] = useState('');
  const [filePathLogo, setFilePathLogo] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [textColor, setTextColor] = useState('#000000');
  const [providerActive, setProviderActive] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);

  const [exclusive, setExclusiveActive] = useState(false);
  const [exclusiveAvailable, setExclusiveAvailable] = useState(false);

  const fetchProvider = useCallback(() => {
    setIsLoading(true);
    ProviderService.findOne(providerId)
      .then((result) => {
        const data = result.data ?? null;
        if (data) {
          setProvider(data);
          setFilePath(data?.paywallBannerUrl);
          setFilePathLogo(data?.paywallLogoUrl);
          setBackgroundColor(data?.backgroundColor ?? '#ffffff');
          setTextColor(data?.textColor ?? '#000000');
          setProviderActive(data.active ?? true);
          setExclusiveActive(data.exclusive ?? false);
          setExclusiveAvailable(data.exclusiveAvailable ?? false);
        }
        setIsLoading(false);
      })
      .catch(() => {
        navigate('/');
      });
  }, [navigate, providerId]);

  useEffect(() => {
    // Call the memoized function to fetch the provider data when the component mounts
    if (!providerId) return;
    fetchProvider();
  }, [fetchProvider, providerId]);

  useEffect(() => {
    const getAutomaticRedemptionTotalBalance = async (arProvider) => {
      try {
        const { data } = await AutomaticRedemptionApi.getTotalBalance(
          arProvider,
        );
        setTotalBalance(data?.quota);
      } catch (error) {
        console.error(error);
      }
    };

    if (provider?.arProvider) {
      getAutomaticRedemptionTotalBalance(provider?.arProvider);
    }
  }, [provider]);

  const getCosts = useCallback(() => {
    if (!provider) return;

    SettingsService.getLast(provider?.id)
      .then((result) => {
        const data = result.data ?? null;
        if (data) {
          setCosts(data.cost ?? null);
        }
      })
      .catch((err) => {
        console.error(err?.message);
      });
  }, [provider]);

  const getCostHistory = useCallback(
    (page = 1) => {
      if (!provider) return;

      SettingsService.list(provider?.id, { page, limit: 5 })
        .then((result) => {
          const data = result.data ?? null;
          if (data) {
            setCostsHistory(data ?? null);
          }
        })
        .catch((err) => {
          console.error(err?.message);
        });
    },
    [provider],
  );
  const closeToastBottom = () => {
    setToastBottom((prevState) => ({ ...prevState, show: false }));
  };
  const getStats = useCallback(() => {
    if (!provider) return;

    ProviderService.stats(provider?.id)
      .then((result) => {
        const data = result.data ?? null;
        if (data) {
          setStats(data ?? null);
        }
      })
      .catch((err) => {
        console.error(err?.message);
      });
  }, [provider]);

  useEffect(() => {
    getCosts();
  }, [getCosts]);

  useEffect(() => {
    getCostHistory();
  }, [getCostHistory]);

  useEffect(() => {
    getStats();
  }, [getStats]);

  const saveCostsHandler = (newCost) => {
    SettingsService.create({
      cost: newCost,
      provider: provider?.id,
    }).then((result) => {
      const data = result.data ?? null;
      if (data) {
        setCosts(data.cost ?? null);
      }
      getCostHistory();
      setToast({
        show: true,
        header: 'Cost has been changed',
        class: 'success',
      });
    });
  };

  const onAddProvider = async (providerData) =>
    ProviderService.save(providerData)
      .then((response) => ({
        errors: null,
        data: response?.data,
      }))
      .catch((err) => {
        const errors = err.response.data._errors;
        if (!errors) {
          setToast({
            show: true,
            class: 'error',
            header: 'We could not add the Provider',
            body: `${err.response.status} - ${err.response.statusText}`,
          });
          return null;
        }

        return {
          errors,
          data: null,
          action: () => navigate('/offers'),
        };
      });

  const onModifyProvider = async (providerData) => {
    const updatedProviderData = {
      ...providerData,
      locations,
      exclusive,
    };

    if (!providerId) {
      setSaveTick(0);
      const newProvider = await onAddProvider(updatedProviderData);
      navigate(`/offers/edit/${newProvider.data.id}`);
    } else {
      setSaveTick(0);
      ProviderService.update(providerId, updatedProviderData)
        .then((response) => {
          setToastBottom(() => ({
            show: true,
            success: true,
            body: 'You have successfully saved the offer',
          }));

          return {
            errors: null,
            data: response?.data,
            action: () => dispatch(actions.fetchProviders()),
          };
        })
        .catch((err) => {
          setToastBottom(() => ({
            body: 'Please try again.',
            success: false,
            show: true,
          }));
        });
    }
  };

  const displayConnectionPrice = useMemo(() => !!costs, [costs]);

  const headerClass =
    Math.ceil(scrollTop) > 35
      ? `${styles.companyActionBar} py-3 shadow-sm`
      : '';

  useEffect(() => {
    if (provider?.locations) {
      setLocations(provider.locations);
    }
  }, [provider]);

  const handleLocationsChange = (newLocations) => {
    setLocations(newLocations);
  };

  return (
    <>
      <div className={`${styles.offerDetailSmallScreenAlert}`}>
        <p>
          <small>
            This page is built for <strong>desktop use only</strong>
          </small>
        </p>
      </div>

      <div className={`${styles.offerDetail} py-5`}>
        {/* {loading && <OverlaySpinner />} */}
        <div className={headerClass}>
          <OfferHeader
            providerActive={providerActive}
            filePath={filePath}
            isFormValid={isFormValid}
            provider={provider}
            isLoading={isLoading}
            setSaveTick={setSaveTick}
          />
        </div>

        <StatisticsPanel provider={provider} />

        <OfferStatus
          providerActive={providerActive}
          setProviderActive={setProviderActive}
          provider={provider}
          exclusiveActive={exclusive}
          setExclusiveActive={setExclusiveActive}
          exclusiveAvailable={exclusiveAvailable}
        />

        <OfferAccordion
          onSelect={(key) => setAccordionActiveKey(key)}
          activeKey={accordionActiveKey}
          loading={isLoading}
        >
          <OfferSectionGeneral
            step={1}
            provider={provider}
            setToastBottom={setToastBottom}
            onModifyProvider={onModifyProvider}
            isFormValid={isFormValid}
            setIsFormValid={setIsFormValid}
            filePath={filePath}
            filePathLogo={filePathLogo}
            backgroundColor={backgroundColor}
            textColor={textColor}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            saveTick={saveTick}
            providerActive={providerActive}
          />
          {displayConnectionPrice && (
            <OfferConnectionPrice
              step={2}
              costs={costs}
              saveCostsHandler={saveCostsHandler}
              costsHistory={costsHistory}
              getCostHistory={getCostHistory}
            />
          )}
          <OfferLandingPage
            step={displayConnectionPrice ? 3 : 2}
            filePath={filePath}
            setFilePath={setFilePath}
            filePathLogo={filePathLogo}
            setFilePathLogo={setFilePathLogo}
            backgroundColor={backgroundColor}
            setBackgroundColor={setBackgroundColor}
            textColor={textColor}
            setTextColor={setTextColor}
          />
          {provider && (
            <OfferLocation
              step={displayConnectionPrice ? 4 : 3}
              initialLocations={locations}
              onLocationsChange={handleLocationsChange}
              setIsFormValid={setIsFormValid}
              provider={provider}
            />
          )}
        </OfferAccordion>
      </div>

      <ToasterBottom toast={toastBottom} closeToast={closeToastBottom} />
    </>
  );
};

export default OfferDetail;
