import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

/* Packages */
import { useSelector } from 'react-redux';
import { Row, Col, Container, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
  faArrowLeft,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';

/* Global Components */

import Toaster from '../../../../components/UI/Toaster/Toaster';
import Modal from '../../../../components/UI/Modal/Modal';
import Input from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import ActionsNavbar from '../../../../components/ActionsNavbar/ActionsNavbar';
// import Modal from '../../../components/UI/Modal/Modal';
// import ToggleSwitch from '../../../../components/UI/ToggleSwitch/ToggleSwitch';

/* Local */
import ReportWidget from './ReportWidget/ReportWidget';
import QualifierWidget from './ReportWidget/QualifierWidget';
import ReportFilters from './ReportFilters/ReportFilters';
import { useCampaignReport } from './CampaignReportPage.hooks';
import './FormsReportModal.scss';

/**
 * Campaign Reports - dynamically driven via BE:
 *  1. FE requests all report fields: api/cpdc/report-fields?dataSource={source}
 *  2. RE requests each field data: api/cpdc/report/de99f331-aae5-4974-b07b-26073b8c6af1/118081/{fieldKey}?filters={}
 */

const CampaignReportPage = () => {
  const { id: campaignId } = useParams();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isAdmin, isCompanyAdmin } = useSelector((state) => state.auth);
  const [getters, actions] = useCampaignReport({ campaignId });
  const {
    forms,
    campaign,
    isPaywallCampaign,
    selectedForm,
    filters,
    reportFields,
    showFilters,
    formQuestions,
    qualifierQuestions,
    filePassword,
    showFilePasswordModal,
    fileDownload,
    toast,
  } = getters;

  const {
    downloadCsv,
    onFormChange,
    getCampaignFormsOptions,
    setShowFilters,
    setFilters,
    setFilePassword,
    setShowFilePasswordModal,
    closeToast,
  } = actions;

  if (!campaign) {
    return null;
  }

  return (
    <>
      <Toaster toast={toast} closeToast={closeToast} />
      <ActionsNavbar>
        <Col
          className="
                    col-auto
                    mb-2
                    mb-md-0
                    d-flex
                    flex-wrap
                    align-content-center"
        >
          <Button
            additionalClasses="
                        _add-campaign
                        btnDefault--with-icon
                        btnDefault--with-icon-left"
            clicked={() => {
              if (searchParams.get('filter')) {
                navigate('/analytics?filter=1');
              } else {
                navigate(-1);
              }
            }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              title="Return"
              className=" btnDefault--with-icon__icon"
            />
            Return
          </Button>
        </Col>
        {forms.length > 1 && (
          <Col xs sm="6" md="4" className="text-right ml-auto">
            <Input
              label="Select form"
              elementType="select"
              additionalClasses="formInput formInput--bordered"
              dataTestAttribute="forms-report-form-select"
              elementConfig={{
                options: getCampaignFormsOptions(campaign),
              }}
              value={selectedForm.id}
              changed={(event) => onFormChange(event)}
            />
          </Col>
        )}
      </ActionsNavbar>
      <Container fluid data-test-id="cpdc-report">
        <Row>
          <Col className="mx-auto col-auto">
            {!forms.length && (
              <div className="text-center mt-5">
                <p>This page does not have any connect forms.</p>
                <p className="text-muted">
                  Please add connect form to the landing page to collect
                  responses.
                </p>
              </div>
            )}
          </Col>
        </Row>
        {forms.length > 0 && (
          <>
            <Row>
              <Col>
                <div className="px-5 my-4">
                  <div className="d-flex align-items-center">
                    <h1>Connections Answers Reports</h1>
                    {/* <ToggleSwitch
                                            className="ms-auto"
                                            label="Post Connect Form Data"
                                            value={false}
                                            onCheck={() =>
                                                setShowPostQuestionData(
                                                    (bool) => !bool,
                                                )
                                            }
                                            darkMode
                                        /> */}
                  </div>
                  <div className="d-flex mt-4">
                    <Button
                      additionalClasses="btnDefault--medium me-3"
                      clicked={() => setShowFilters((oldVal) => !oldVal)}
                      dataTestAttribute="cpdc-report-show-filters"
                    >
                      {showFilters ? 'Hide filters' : 'Show filters'}
                    </Button>
                    {(isAdmin || isCompanyAdmin) && (
                      <Dropdown>
                        <Dropdown.Toggle
                          className="btnDefault btnDefault--medium btnDefault--with-icon btnDefault--with-icon-add"
                          id="download-csv"
                          data-test-id="download-csv-button"
                        >
                          Download csv
                          <FontAwesomeIcon
                            icon={faDownload}
                            title="Download CSV"
                            className=" btnDefault--with-icon__icon"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => downloadCsv('connect-form')}
                          >
                            Connect form
                            {fileDownload.connectForm && (
                              <FontAwesomeIcon
                                fixedWidth
                                className="ms-3"
                                icon={faCircleNotch}
                                spin
                              />
                            )}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => downloadCsv('post-questions')}
                          >
                            Post Connect form
                            {fileDownload.postForm && (
                              <FontAwesomeIcon
                                fixedWidth
                                className="ms-3"
                                icon={faCircleNotch}
                                spin
                              />
                            )}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                </div>
                {selectedForm && reportFields && (
                  <ReportFilters
                    currentFilters={filters}
                    fields={formQuestions}
                    onFiltersChange={setFilters}
                    campaignId={campaign.id}
                    formId={selectedForm.id}
                    showFilters={showFilters}
                  />
                )}
              </Col>
            </Row>

            {isPaywallCampaign ? (
              <Row>
                <Col>
                  <div className="px-5 mt-4">
                    <h2>Paywall Qualifiers</h2>
                  </div>
                </Col>
              </Row>
            ) : null}

            {isPaywallCampaign ? (
              <Row>
                <Col>
                  <div className="reportWidgets-wrapper">
                    {qualifierQuestions.map((question, idx, questions) => (
                      <QualifierWidget
                        key={question.questionId}
                        questions={questions.filter(
                          (q) => q.questionId !== question.questionId,
                        )}
                        campaignId={campaign.id}
                        questionId={question.questionId}
                        questionName={question.questionLabel.en}
                        filters={filters}
                        isAggregated={question.isAggregated}
                        unit="count"
                        countType="endless"
                      />
                    ))}
                    {!qualifierQuestions.length && (
                      <p className="px-3 mt-2">No user data available yet.</p>
                    )}
                  </div>
                </Col>
              </Row>
            ) : null}

            {isPaywallCampaign && (
              <Row>
                <Col>
                  <div className="px-5 mt-2">
                    <h2>Campaign</h2>
                  </div>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <div className="reportWidgets-wrapper">
                  {formQuestions.map((question, idx, questions) => (
                    <ReportWidget
                      key={question.name}
                      questions={questions.filter(
                        (q) => q.name !== question.name,
                      )}
                      campaignId={campaign.id}
                      formId={selectedForm.id}
                      questionName={question.name}
                      reportFields={reportFields}
                      filters={filters}
                      unit={question.unit}
                      countType={question.countType}
                    />
                  ))}
                  {!formQuestions.length && (
                    <p className="px-3 mt-2">
                      There are no questions in the connect form. Please add
                      custom question in the connect form settings in the
                      landing page editor.
                    </p>
                  )}
                </div>
              </Col>
            </Row>
          </>
        )}
      </Container>

      {showFilePasswordModal && (
        <Modal
          title="File password"
          body={
            <>
              <p>The password to extract the zip file is:</p>
              <p>
                <strong>{filePassword}</strong>
              </p>
              <p>
                The csv file contains PII (personally identifiable information)
                and should be treated with care
              </p>
              <p className="mb-0">
                It is recommended to use a program such as 7-zip to extract the
                csv file
              </p>
            </>
          }
          show={showFilePasswordModal}
          handleClose={() => {
            setFilePassword(false);
            setShowFilePasswordModal(false);
          }}
        />
      )}
    </>
  );
};

export default CampaignReportPage;
