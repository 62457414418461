import React, { useState } from 'react';

import styles from './OfferStatus.module.scss';
import ToggleSwitch from '../../UI/ToggleSwitch/ToggleSwitch';
import Toaster from '../../UI/Toaster/Toaster';

const OfferStatus = ({
  provider,
  providerActive,
  setProviderActive,
  exclusiveActive,
  setExclusiveActive,
  exclusiveAvailable,
}) => {
  const [toast, setToast] = useState({ show: false, header: '', class: '' });

  const copyTextUrl = () => {
    navigator.clipboard.writeText(
      `${
        process.env.REACT_APP_PUBLIC_PAGES_URL
      }/paywall/${provider.slug.toLowerCase()}`,
    );
    setToast({
      show: true,
      class: 'success',
      header: 'Copied to clipboard!',
    });
  };

  const closeToast = () => {
    setToast((prevState) => ({ ...prevState, show: false }));
  };

  if (!provider) return null;

  return (
    <div className={styles.outer}>
      <div className="d-flex align-items-center gap-3">
        <span className={styles.statusText}>OFFER STATUS</span>{' '}
        <ToggleSwitch
          value={providerActive}
          darkMode
          label="ACTIVE"
          additionalLabelClasses="toggleSwitch__label--lower toggleSwitch__label--sm text-bold"
          onCheck={(e) => setProviderActive(e)}
        />
        <ToggleSwitch
          value={exclusiveActive}
          darkMode
          label="EXCLUSIVE"
          disabled={!exclusiveAvailable}
          additionalLabelClasses="toggleSwitch__label--lower toggleSwitch__label--sm text-bold"
          onCheck={(e) => setExclusiveActive(e)}
        />
        <div className={styles.divider} />
        <span className={styles.statusText}>PUBLIC URL</span>{' '}
        <div
          className={`p-2 border d-flex justify-content-between align-items-center ${styles.urlContainer}`}
        >
          <span>
            {process.env.REACT_APP_PUBLIC_PAGES_URL}/paywall/
            {provider.slug.toLowerCase()}
          </span>
          <button onClick={copyTextUrl} className={styles.copy}>
            <CopySvg />
          </button>
        </div>
      </div>

      <Toaster toast={toast} closeToast={closeToast} />
    </div>
  );
};

const CopySvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M14.8 1H1.2C1.08954 1 1 1.08954 1 1.2V14.8C1 14.9105 1.08954 15 1.2 15H14.8C14.9105 15 15 14.9105 15 14.8V1.2C15 1.08954 14.9105 1 14.8 1Z"
      stroke="#D1D1D1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 2.99998V16.2C17 16.642 16.642 17 16.2 17H3"
      stroke="#D1D1D1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default OfferStatus;
