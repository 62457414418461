import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as locales from 'react-date-range/dist/locale';
import { DateRangePicker } from 'react-date-range';
import { Badge, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDays } from 'date-fns';
import {
  faShareNodes,
  faCalendarAlt,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faRotateLeft,
  faTimes,
  faCopy,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import { v4 as uuidv4 } from 'uuid';

import AnalyticsService from '../../api/Analytics';
import CompanyService from '../../api/Companies';
import ToggleSwitch from '../../components/UI/ToggleSwitch/ToggleSwitch';

import styles from './AnalyticsPage.module.scss';
import Button from '../../components/UI/Button/Button';
import { formatDate } from '../../shared/dateTime';
import CampaignList from './CampaignList';
import { getCardValue } from '../../shared/analytics';
import { useLocalStorage } from '../../hooks/useLocalStorage';

/**
 * @desc Get host from window
 * @note HOST is not found in env variable in staging and production getting from window object for now
 */
const HOST = window.location.origin;

const AnalyticsPage = () => {
  const { getItem, setItem } = useLocalStorage('analyticsFilter');
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateType, setDateType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isOpenDateRange, setIsOpenDateRange] = useState(false);
  const [isOpenShareLink, setIsOpenShareLink] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);
  const [slug, setSlug] = useState('');
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    companies: [],
    providers: [],
  });
  const [showCompanies, setShowCompanies] = useState(true);
  const [showOffers, setShowOffers] = useState(true);

  const [analyticsData, setAnalyticsData] = useState([]);
  const [showActiveCampaigns, setShowActiveCampaigns] = useState(true);
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    if (searchParams.get('filter')) {
      const storedFilter = JSON.parse(getItem());

      if (storedFilter) {
        setSelectedCountry(storedFilter.selectedCountry);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setFilterOptions({ companies: [], providers: [] });
    setSelectedCompanies([]);
    setSelectedProviders([]);

    if (selectedCountry) {
      const { data } = await CompanyService.list({
        countries: selectedCountry,
      });

      setAnalyticsData(data.data);

      setFilterOptions((prevState) => ({
        ...prevState,
        companies: data.data.map((company) => company.name),
      }));

      const storedFilter = JSON.parse(getItem());
      if (searchParams.get('filter') && storedFilter) {
        setSelectedCompanies(storedFilter.selectedCompanies);
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  const resetData = async () => {
    setSearchParams({});
    setFilterOptions({ companies: [], providers: [] });
    setSelectedCompanies([]);
    setSelectedProviders([]);

    if (selectedCountry) {
      const { data } = await CompanyService.list({
        countries: selectedCountry,
      });

      setAnalyticsData(data.data);

      setFilterOptions((prevState) => ({
        ...prevState,
        companies: data.data.map((company) => company.name),
      }));
    }
  };

  useEffect(() => {
    setFilterOptions((prevState) => {
      const newProviders = analyticsData
        .filter((company) => selectedCompanies.includes(company.name))
        .flatMap((company) => {
          const providerNames = company.providers.split(',');
          const providerIds = company.providerIds.split(',');

          const providerObjects = providerNames.map((providerName, index) => {
            return {
              id: providerIds[index],
              name: providerName,
            };
          });

          return [...providerObjects];
        });

      return {
        ...prevState,
        providers: [...new Set(newProviders)], // Remove duplicates
      };
    });

    const storedFilter = JSON.parse(getItem());

    if (searchParams.get('filter') && storedFilter) {
      setSelectedProviders(storedFilter.selectedProviders);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanies]);

  const fetchCampaigns = async (date = {}) => {
    setIsLoading(true);
    try {
      const result = await AnalyticsService.list({
        limit: 12,
        page: 1,
        order: 'endDate',
        order_direction: 'ASC',
        status: 'active',
        fallback: false,
        stats: true,
        countData: true,
        videoProvider: selectedProviders,
        countries: selectedCountry ? [selectedCountry] : [],
        rangeStart: date?.startDate,
        rangeEnd: date?.endDate,
      });

      setCampaigns(result.data.data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setCampaigns([]); // Set empty array on error
    } finally {
      setIsLoading(false);
    }
  };

  const onCompanySelect = useCallback((e, company) => {
    const { checked } = e.target;

    setSelectedCompanies((prev) =>
      checked ? [...prev, company] : prev.filter((c) => c !== company),
    );
  }, []);

  const onProviderSelect = useCallback((e, provider) => {
    const { checked } = e.target;

    setSelectedProviders((prev) =>
      checked ? [...prev, provider] : prev.filter((p) => p !== provider),
    );
  }, []);

  const countryOptions = ['Indonesia', 'Philippines', 'Thailand', 'N/A'];
  const cardItems = ['Total Connections', 'Budget Spent', 'Live Campaigns'];

  const toggleActiveCampaigns = () => {
    setShowActiveCampaigns(!showActiveCampaigns);
  };

  const handleDateRangeChange = (type) => {
    if (dateType === type && dateType !== 4) {
      onClearDate();
      return;
    }

    const date = new Date();
    const endDate = formatDate(date, 'yyyy/MM/dd');

    switch (type) {
      case 1:
        setDateType(1);

        setDateRange([
          {
            startDate: date,
            endDate,
            key: 'selection',
          },
        ]);

        fetchCampaigns({
          startDate: formatDate(date, 'yyyy/MM/dd'),
          endDate,
        });

        break;
      case 2:
        setDateType(2);

        setDateRange([
          {
            startDate: new Date(date.setDate(date.getDate() - 1)),

            endDate,
            key: 'selection',
          },
        ]);

        fetchCampaigns({
          startDate: formatDate(
            new Date(date.setDate(date.getDate() - 1)),
            'yyyy/MM/dd',
          ),
          endDate,
        });
        break;
      case 3:
        setDateType(3);

        setDateRange([
          {
            startDate: new Date(date.setDate(date.getDate() - 2)),

            endDate,
            key: 'selection',
          },
        ]);

        fetchCampaigns({
          startDate: formatDate(
            new Date(date.setDate(date.getDate() - 2)),
            'yyyy/MM/dd',
          ),
          endDate,
        });
        break;
      case 4:
        setDateType(4);
        setIsOpenDateRange(false);
        fetchCampaigns({
          startDate: formatDate(dateRange[0].startDate, 'yyyy/MM/dd'),
          endDate: formatDate(dateRange[0].endDate, 'yyyy/MM/dd'),
        });
        break;
      default:
        return null;
    }
  };

  const onOpenDateRange = () => {
    setIsOpenDateRange((prevState) => !prevState);
  };

  const onApplyClick = () => {
    setDateType(null);
    setItem(
      JSON.stringify({
        selectedCountry,
        selectedCompanies,
        selectedProviders,
      }),
    );

    fetchCampaigns();
  };

  const onClearDate = () => {
    setDateType(null);
    fetchCampaigns();
  };

  const saveNewSettings = async () => {
    setIsSaveLoading(true);
    await AnalyticsService.save({
      slug,
      settings: JSON.stringify({
        videoProvider: selectedProviders,
        countries: selectedCountry ? [selectedCountry] : [],
        isActiveOnly: showActiveCampaigns,
        byRange: dateType !== null,
        rangeStart: dateRange[0].startDate,
        rangeEnd: dateRange[0].endDate,
      }),
    });

    await navigator.clipboard.writeText(`${HOST}/public/${slug}/analytics`);
    setIsSaveLoading(false);
    setIsOpenShareLink(false);
  };

  return (
    <Container fluid className="p-5">
      <Row>
        <Col lg={4}>
          <Card className={styles.analyticsOption} border="light">
            <div className="mb-5">
              <h5>Companies and Offers</h5>
              <small style={{ color: '#252525' }}>
                Select an offer or company to see statistics on campaigns
              </small>
            </div>

            {!selectedCountry &&
              countryOptions.map((country) => (
                <div key={country}>
                  <div
                    style={{ cursor: 'pointer' }}
                    className="d-flex align-items-center justify-content-between"
                    onClick={() => setSelectedCountry(country)}
                  >
                    <h6 className="m-0">{country}</h6>

                    <FontAwesomeIcon
                      icon={faChevronRight}
                      color="orange"
                      cursor="pointer"
                    />
                  </div>
                  <hr />
                </div>
              ))}

            {selectedCountry && (
              <div className="user-select-none">
                <div className="d-flex align-items-center gap-3">
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    color="orange"
                    cursor="pointer"
                    onClick={() => setSelectedCountry(null)}
                  />
                  <h6 className="m-0">{selectedCountry}</h6>
                </div>
                <hr />
                <div className="mb-5">
                  <div className="mb-3">
                    <div
                      className="d-flex align-items-center justify-content-between mb-3"
                      onClick={() => setShowCompanies(!showCompanies)}
                    >
                      <h6 className="m-0">
                        <strong>Companies</strong>
                      </h6>

                      <FontAwesomeIcon
                        icon={showCompanies ? faChevronDown : faChevronRight}
                        color="orange"
                        cursor="pointer"
                      />
                    </div>
                    {showCompanies && (
                      <div
                        style={{
                          maxHeight: '200px',
                          overflow: 'auto',
                          userSelect: 'none',
                          padding: '5px 0',
                        }}
                      >
                        {filterOptions.companies.map((company) => (
                          <div
                            className="d-flex align-items-center gap-1 mb-2 mx-1 "
                            key={company}
                          >
                            <Form.Check
                              inline
                              name="group1"
                              type="checkbox"
                              checked={selectedCompanies.includes(company)}
                              onChange={(e) => onCompanySelect(e, company)}
                            />
                            <span
                              onClick={() =>
                                onCompanySelect(
                                  {
                                    target: {
                                      checked:
                                        !selectedCompanies.includes(company),
                                    },
                                  },
                                  company,
                                )
                              }
                              className="m-0"
                            >
                              {company}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div>
                    <div
                      className="d-flex align-items-center justify-content-between mb-3"
                      onClick={() => setShowOffers(!showOffers)}
                    >
                      <h6 className="m-0">
                        <strong>Offers</strong>
                      </h6>

                      <FontAwesomeIcon
                        icon={showOffers ? faChevronDown : faChevronRight}
                        color="orange"
                        cursor="pointer"
                      />
                    </div>
                  </div>
                  {showOffers && (
                    <div
                      style={{
                        maxHeight: '200px',
                        overflow: 'auto',
                        userSelect: 'none',
                      }}
                    >
                      {filterOptions.providers.map((provider) => {
                        if (!provider.id || !provider.name) return null;
                        return (
                          <div
                            className="d-flex align-items-center gap-1 mb-2 mx-1"
                            key={provider.id}
                          >
                            <Form.Check
                              inline
                              name="group2"
                              type="checkbox"
                              checked={selectedProviders.includes(provider.id)}
                              onChange={(e) => onProviderSelect(e, provider.id)}
                            />
                            <span
                              onClick={() =>
                                onProviderSelect(
                                  {
                                    target: {
                                      checked: !selectedProviders.includes(
                                        provider.id,
                                      ),
                                    },
                                  },
                                  provider.id,
                                )
                              }
                              className="m-0"
                            >
                              {provider.name}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="d-flex gap-2">
                  <Button
                    additionalClasses="btnDefault--medium btnDefault--white"
                    onClick={resetData}
                  >
                    <FontAwesomeIcon icon={faRotateLeft} />
                  </Button>
                  <Button
                    additionalClasses="btnDefault--medium flex-grow-1"
                    onClick={onApplyClick}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            )}
          </Card>
        </Col>
        <Col>
          <Row className="mb-3">
            <Col>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="m-0">Analytics Board</h4>

                {selectedCompanies.length > 0 &&
                selectedProviders.length > 0 ? (
                  <div className="d-flex gap-2 align-items-center">
                    {dateType === 4 ? (
                      <Card
                        className={`${styles.analyticsDateCard} justify-content-center align-items-center flex-row gap-4`}
                      >
                        <small>
                          {formatDate(
                            dateRange[0].startDate,
                            'yyyy/MM/dd HH:mm',
                          )}{' '}
                          -{' '}
                          {formatDate(dateRange[0].endDate, 'yyyy/MM/dd HH:mm')}
                        </small>
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="ms-1"
                          color="#ff6f00ff"
                          cursor="pointer"
                          onClick={onClearDate}
                        />
                      </Card>
                    ) : (
                      <>
                        <Button
                          additionalClasses={`btnDefault--medium btnDefault--white ${
                            dateType === 3 && styles.dateButtonActive
                          }`}
                          onClick={() => handleDateRangeChange(3)}
                        >
                          Last 48 Hours
                        </Button>
                        <Button
                          additionalClasses={`btnDefault--medium btnDefault--white ${
                            dateType === 2 && styles.dateButtonActive
                          }`}
                          onClick={() => handleDateRangeChange(2)}
                        >
                          Last 24 Hours
                        </Button>
                        <Button
                          additionalClasses={`btnDefault--medium btnDefault--white ${
                            dateType === 1 && styles.dateButtonActive
                          }`}
                          onClick={() => handleDateRangeChange(1)}
                        >
                          Today
                        </Button>
                      </>
                    )}

                    <div style={{ position: 'relative' }}>
                      <Button
                        additionalClasses={`btnDefault--medium btnDefault--white ${
                          dateType === 4 && styles.dateButtonActive
                        }`}
                        onClick={onOpenDateRange}
                      >
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </Button>
                      {isOpenDateRange && (
                        <Card
                          className="shadow"
                          style={{
                            position: 'absolute',
                            right: 0,
                            top: '45px',
                            zIndex: 10,
                            padding: '10px',
                          }}
                        >
                          <DateRangePicker
                            onChange={(item) => setDateRange([item.selection])}
                            months={2}
                            ranges={dateRange}
                            direction="horizontal"
                            dateFormat="yyyyMMdd"
                            locale={locales.enUS}
                          />
                          <div className="d-flex justify-content-end gap-2">
                            <Button
                              variant="light"
                              className="py-1 px-3 shadow-none"
                              additionalClasses="btnDefault--medium btnDefault--light"
                              onClick={() => setIsOpenDateRange(false)}
                            >
                              <span style={{ color: 'black' }}>Close</span>
                            </Button>
                            <Button
                              additionalClasses="btnDefault--medium  "
                              onClick={() => handleDateRangeChange(4)}
                            >
                              Apply
                            </Button>
                          </div>
                        </Card>
                      )}
                    </div>

                    <div style={{ position: 'relative' }}>
                      <Button
                        additionalClasses="btnDefault--medium"
                        onClick={() => {
                          if (!slug) {
                            setSlug(uuidv4().split('-')[0]);
                          }
                          setIsOpenShareLink(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faShareNodes} />
                      </Button>
                      {isOpenShareLink && (
                        <Card
                          className={`shadow ${styles.analyticsCard}`}
                          style={{
                            position: 'absolute',
                            right: 0,
                            top: '45px',
                            zIndex: 10,
                            padding: '30px',
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <h6 className="mb-0">Extrnal Link</h6>
                            <FontAwesomeIcon
                              color="red"
                              icon={faTimes}
                              cursor="pointer"
                              onClick={() => {
                                setIsOpenShareLink(false);
                              }}
                            />
                          </div>

                          <div className="d-flex align-items-center gap-3">
                            <small>
                              {HOST}/public/{slug}
                              /analytics
                            </small>
                            {isSaveLoading ? (
                              <FontAwesomeIcon icon={faSpinner} spin />
                            ) : (
                              <FontAwesomeIcon
                                icon={faCopy}
                                cursor="pointer"
                                onClick={saveNewSettings}
                              />
                            )}
                          </div>
                        </Card>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            {cardItems.map((key) => (
              <Col key={key}>
                <Card className={styles.analyticsCard}>
                  <small style={{ color: '#252525' }}>{key}</small>
                  <div className="d-flex align-items-center gap-3">
                    <strong style={{ fontSize: '32px', margin: 0 }}>
                      {getCardValue(key, campaigns, showActiveCampaigns)}
                    </strong>
                    {key === 'Total Connections' && (
                      <span>
                        <Badge bg="success">
                          +{' '}
                          {campaigns.reduce(
                            (total, campaign) =>
                              total + campaign.countData.connectionsToday,
                            0,
                          )}{' '}
                          Today
                        </Badge>
                      </span>
                    )}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <hr />
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h6 className="m-0">Campaigns</h6>
                <div className="d-flex justify-content-between align-items-center">
                  <small>Show active campaigns</small>
                  <ToggleSwitch
                    darkMode
                    value={showActiveCampaigns}
                    onCheck={toggleActiveCampaigns}
                    className="ms-2"
                    additionalLabelClasses="toggleSwitch__label--lower toggleSwitch__label--sm"
                  />
                </div>
              </div>

              <CampaignList
                isLoading={isLoading}
                showActiveCampaigns={showActiveCampaigns}
                campaigns={campaigns}
                selectedProviders={selectedProviders}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AnalyticsPage;
