import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Carousel from '../UI/Carousel/Carousel';
import { SpinnerIcon } from '../UI/OverlaySpinner/OverlaySpinner';

import styles from './Dashboard.module.scss';

import ProviderService from '../../api/Providers';

const groupInFours = (array) => {
  return Array.from({ length: Math.ceil(array.length / 4) }, (_, index) =>
    array.slice(index * 4, index * 4 + 4),
  );
};

const DashboardProviderSection = () => {
  const [providerGroups, setProviderGroups] = useState([]);
  const [providersLowOnCodes, setProvidersLowOnCodes] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { isAdvertisingPartner } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const getProviders = async () => {
    try {
      const result = await ProviderService.list({
        limit: 12,
        page: 1,
        order: 'codes',
        order_direction: 'ASC',
        extended: true,
        status: 'active',
      });

      setProviderGroups(groupInFours(result.data.data));
      setProvidersLowOnCodes(
        result.data.data.reduce((total, provider) => {
          if (provider.availableCodes < 100) {
            return total + 1;
          }
          return total;
        }, 0),
      );

      setLoading(false);
    } catch (err) {
      process.env.NODE_ENV !== 'production' && console.error(err);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProviders();
  }, []);

  if (error) {
    return (
      <div
        className={`alert alert-danger mb-0 h-100 d-flex justify-content-center align-items-center ${styles.dashboardContainer}`}
        role="alert"
      >
        Unable to load Offers
      </div>
    );
  }

  if (loading) {
    return (
      <div
        className={`d-flex justify-content-center align-items-center ${styles.dashboardContainer}`}
      >
        <SpinnerIcon />
      </div>
    );
  }

  return (
    <div className={`${styles.dashboardContainer}`}>
      <div
        className={`d-flex justify-content-between align-items-center ${
          isAdvertisingPartner ? 'mb-5' : 'mb-2'
        }`}
      >
        <h2 className="mb-0">Offers</h2>
        {!isAdvertisingPartner && (
          <button
            onClick={() => navigate('/offers')}
            className={styles.chevronRightButton}
          >
            <span className="me-3">See All</span>
            <div>
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.15356 1L5.14391 5L1.15356 9"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </button>
        )}
      </div>

      {!isAdvertisingPartner && (
        <div className="mb-3">
          <p className="mb-0 extra-small">
            {providersLowOnCodes} offers running low on codes
          </p>
        </div>
      )}
      <div>
        <Carousel>
          {providerGroups.map((providerGroup, idx) => (
            <div
              key={`providerGroup-${idx}`}
              className={`d-flex justify-content-between align-items-center flex-column ${styles.providerGroup}`}
            >
              {providerGroup.map((provider) => (
                <div
                  key={`provider-${provider.id}`}
                  className={`d-flex justify-content-between align-items-center ${styles.providerCard}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isAdvertisingPartner) {
                      window.open(
                        `${
                          process.env.REACT_APP_PUBLIC_PAGES_URL
                        }/paywall/${provider.slug.toLowerCase()}`,
                        '_blank',
                      );
                    } else {
                      navigate(`/offers/edit/${provider.id}`);
                    }
                  }}
                >
                  <p className="mb-0">{provider.name}</p>
                  {!isAdvertisingPartner ? (
                    <div className="d-flex justify-content-end align-items-center">
                      <p className="mb-0 me-2">
                        {provider.stats.availableCodes} Codes Remaining
                      </p>
                      <svg
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/codes/${provider.id}`);
                        }}
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <ellipse
                          cx="12.2181"
                          cy="12"
                          rx="11.971"
                          ry="12"
                          fill="#FF6F00"
                        />
                        <path
                          d="M12.218 8V16"
                          stroke="white"
                          strokeLinecap="round"
                        />
                        <path
                          d="M16.2085 12L8.2278 12"
                          stroke="white"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                  ) : (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(
                          `${process.env.REACT_APP_PUBLIC_PAGES_URL
                          }/paywall/${provider.slug.toLowerCase()}`,
                          '_blank',
                        );
                      }}
                      className={styles.chevronRightButton}
                      aria-label="Open provider paywall"
                    >
                      <div>
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.15356 1L5.14391 5L1.15356 9"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button>
                  )}
                </div>
              ))}
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default DashboardProviderSection;
