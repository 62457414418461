import React, { useEffect, useState } from 'react';
import { Badge, Card, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import AnalyticsService from '../../api/Analytics';
import styles from './AnalyticsPage.module.scss';
import { formatDate } from '../../shared/dateTime';
import CampaignList from './CampaignList';
import { getCardValue } from '../../shared/analytics';

const PublicAnalyticsPage = () => {
  const { slug } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [generatedDate, setGeneratedDate] = useState('');

  const fetchCampaigns = async () => {
    setIsLoading(true);
    try {
      const result = await AnalyticsService.list({
        limit: 12,
        page: 1,
        order: 'endDate',
        order_direction: 'ASC',
        status: 'active',
        fallback: false,
        stats: true,
        countData: true,
        slug,
      });

      setCampaigns(result.data.data);
      setGeneratedDate(result.data.generatedDate);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setCampaigns([]); // Set empty array on error
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cardItems = ['Total Connections', 'Budget Spent', 'Live Campaigns'];

  return (
    <Container className="p-5">
      <Row>
        <Col>
          <Row className="mb-3">
            <Col>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="m-0">Analytics Board</h4>
                <div className="d-flex gap-2 align-items-center">
                  <Card
                    className={`${styles.analyticsDateCard} justify-content-center align-items-center flex-row gap-2`}
                  >
                    <FontAwesomeIcon
                      icon={faClock}
                      color="#ff6f00ff"
                      cursor="pointer"
                    />
                    <small>
                      Generated on{' '}
                      {formatDate(new Date(generatedDate), 'yyyy/MM/dd HH:mm')}
                    </small>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            {cardItems.map((key) => (
              <Col key={key}>
                <Card className={styles.analyticsCard}>
                  <small style={{ color: '#252525' }}>{key}</small>
                  <div className="d-flex align-items-center gap-3">
                    <strong style={{ fontSize: '32px', margin: 0 }}>
                      {getCardValue(key, campaigns)}
                    </strong>
                    {key === 'Total Connections' && (
                      <span>
                        <Badge bg="success">
                          +{' '}
                          {campaigns.reduce(
                            (total, campaign) =>
                              total + campaign.countData.connectionsToday,
                            0,
                          )}{' '}
                          Today
                        </Badge>
                      </span>
                    )}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <hr className="my-4" />
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h6 className="m-0">Campaigns</h6>
              </div>

              <CampaignList isLoading={isLoading} campaigns={campaigns} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PublicAnalyticsPage;
