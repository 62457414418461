import instance from './Api';

const list = (filters) => {
  const withDefaultFilters = {
    ...filters,
  };

  let query = '';
  Object.keys(withDefaultFilters).forEach((filter) => {
    query += `${(query === '' ? '?' : '&') + filter}=${
      withDefaultFilters[filter]
    }`;
  });
  return instance.get(`/analytics${query}`);
};

const save = (settings) => instance.post('/analytics', settings);

export default {
  list,
  save,
};
