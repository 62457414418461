import React, { useState, useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { OfferAccordionItem } from '../OfferAccordion/OfferAccordion';
import styles from '../OfferDetail.module.scss';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';

const OfferLocation = ({
  step,
  initialLocations,
  onLocationsChange,
  setIsFormValid,
  provider,
}) => {
  const [enableLocations, setEnableLocations] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [showInitialAddresses, setShowInitialAddresses] = useState([]);

  useEffect(() => {
    if (initialLocations?.length > 0) {
      setEnableLocations(true);
      setAddresses(initialLocations);
      setShowInitialAddresses(new Array(initialLocations.length).fill(true));
    }
  }, [initialLocations]);

  const isAddressValid = (address) => Boolean(address);

  const handleEnableLocations = (event) => {
    const isEnabled = event.target.checked;
    setEnableLocations(isEnabled);
    if (isEnabled) {
      onLocationsChange(['']);
      setIsFormValid(false);
      setShowInitialAddresses([true]);
    } else {
      setAddresses(['']);
      onLocationsChange([]);
      setIsFormValid(true);
      setShowInitialAddresses([]);
    }
  };

  const handleAddressChange = (index, value) => {
    const newAddresses = [...addresses];
    newAddresses[index] = value;
    setAddresses(newAddresses);
    onLocationsChange(newAddresses);
    setIsFormValid(newAddresses.some(isAddressValid));

    const newShowInitialAddresses = [...showInitialAddresses];
    newShowInitialAddresses[index] = false;
    setShowInitialAddresses(newShowInitialAddresses);
  };

  const addAddressLine = () => {
    setAddresses([...addresses, '']);
    setShowInitialAddresses([...showInitialAddresses, true]);
  };

  const removeAddressLine = (index) => {
    const newAddresses = addresses.filter((_, i) => i !== index);
    setAddresses(newAddresses);
    onLocationsChange(newAddresses);
    setIsFormValid(newAddresses.some(isAddressValid));

    const newShowInitialAddresses = showInitialAddresses.filter(
      (_, i) => i !== index,
    );
    setShowInitialAddresses(newShowInitialAddresses);
  };

  const isValid = !enableLocations || addresses.some(isAddressValid);

  return (
    <OfferAccordionItem
      step={step}
      title="Location Settings"
      body={
        <Row>
          <Col xs={12}>
            <Form.Check
              type="checkbox"
              id="enable-locations"
              label="Enable Locations"
              checked={enableLocations}
              onChange={handleEnableLocations}
              className="mb-3"
            />

            <div className={styles.previewLink}>
              <span>Preview:</span>
              <a
                href={`${
                  process.env.REACT_APP_PUBLIC_PAGES_URL
                }/paywall/${provider.slug.toLowerCase()}?location=1`}
                target="blank"
              >
                Click Here
              </a>
            </div>
            {enableLocations && (
              <div
                className={`${styles.locationAddresses} ${
                  !isValid ? styles.invalid : ''
                }`}
              >
                <h5>Location Addresses</h5>
                {addresses.map((address, index) => (
                  <div key={index} className="d-flex mb-2 align-items-center">
                    <div style={{ width: '50%' }}>
                      <Input
                        elementType="google-autocomplete"
                        additionalClasses="formInput formInput--bordered formInput--text-sm"
                        value={address}
                        label={`Address ${index + 1}`}
                        elementConfig={{
                          type: 'text',
                          placeholder: 'Enter address',
                        }}
                        changed={(event) =>
                          handleAddressChange(index, event.target.value)
                        }
                      />
                      {showInitialAddresses[index] && address.address && (
                        <span className={styles.initialAddress}>
                          {address.address}
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                              address.address,
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.mapLink}
                          >
                            View on Google Maps
                          </a>
                        </span>
                      )}
                    </div>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => removeAddressLine(index)}
                      title="Remove address"
                      className={`${styles.iconStyling} ms-2`}
                    />
                  </div>
                ))}
                <Button btnType="Primary" clicked={addAddressLine}>
                  Add Address Line
                </Button>
              </div>
            )}
          </Col>
        </Row>
      }
    />
  );
};

export default OfferLocation;
