import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Placeholder } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styles from './AnalyticsPage.module.scss';
import { formatDate } from '../../shared/dateTime';
import Button from '../../components/UI/Button/Button';

const CampaignList = ({
  isLoading,
  showActiveCampaigns,
  campaigns,
  selectedProviders,
}) => {
  const { slug } = useParams();

  const navigate = useNavigate();

  if (isLoading) {
    return (
      <Placeholder className="p-5" as={Card.Text} animation="glow">
        <Placeholder xs={7} className="mx-1 rounded-1" />
        <Placeholder className="mx-1 rounded-1" xs={4} />
        <Placeholder className="mx-1 rounded-1" xs={4} />
        <Placeholder className="mx-1 rounded-1" xs={7} />
        <Placeholder className="mx-1 rounded-1" xs={8} />
        <Placeholder className="mx-1 rounded-1" xs={3} />
      </Placeholder>
    );
  }
  const isActiveListEmpty =
    campaigns.filter(
      (campaign) =>
        (campaign.status === 1 || campaign.status === 10) &&
        showActiveCampaigns,
    ).length === 0;

  if (campaigns && campaigns.length > 0) {
    return (
      <div className={styles.campaignList}>
        {campaigns.map((campaign) => {
          if (
            showActiveCampaigns &&
            campaign.status !== 1 &&
            campaign.status !== 10
          )
            return null;

          return (
            <Card
              key={campaign.id}
              className={`${styles.campaignCard} shadow-sm`}
            >
              <Card.Body className="p-3">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="d-flex align-items-center gap-5">
                    <h6 className="m-0 fw-bold">{campaign.name}</h6>
                    <Card className={`${styles.analyticsCard} p-2`}>
                      <small>{campaign.offer}</small>
                    </Card>
                  </div>
                  <div className="d-flex align-items-center gap-3">
                    <small className="text-muted">{`${formatDate(
                      campaign.startDate,
                      'yyyy/MM/dd',
                    )} - ${formatDate(campaign.endDate, 'yyyy/MM/dd')}`}</small>
                    {!slug && (
                      <Button
                        onClick={() =>
                          navigate(
                            `/campaigns/edit/${campaign.id}/report?filter=1`,
                          )
                        }
                        additionalClasses="shadow-none btnDefault--medium btnDefault--light"
                      >
                        <span
                          style={{
                            color: 'black',
                          }}
                        >
                          Analytics{' '}
                        </span>
                        <FontAwesomeIcon
                          color="#ff6f00ff"
                          icon={faArrowRight}
                          className="ms-2"
                        />
                      </Button>
                    )}
                  </div>
                </div>
                <hr className="my-2" />
                <div className="d-flex align-items-center">
                  <div>
                    <strong className="d-block" style={{ fontSize: '1.2rem' }}>
                      {campaign.countData.connections || 0}
                    </strong>
                    <small className="text-muted">Total Connections</small>
                  </div>
                  <div className="d-flex justify-content-center flex-grow-1">
                    <div>
                      <strong
                        className="d-block"
                        style={{ fontSize: '1.2rem' }}
                      >
                        ${' '}
                        {parseFloat(campaign.cpdcCost) *
                          campaign.countData.connections || 0}
                      </strong>
                      <small className="text-muted">Budget Spent</small>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          );
        })}
        {isActiveListEmpty && showActiveCampaigns && (
          <div className="text-center p-5">
            <h6 style={{ color: '#D4D4D4' }}>
              Sorry, there are no active campaigns currently running for the
              selected companies/offers.
            </h6>
          </div>
        )}
      </div>
    );
  }
  if (slug) {
    return (
      <div className="text-center p-5">
        <h6 style={{ color: '#D4D4D4' }}>
          Sorry, there are no campaigns found.
        </h6>
      </div>
    );
  }

  return (
    <div className="text-center p-5">
      <h6 style={{ color: '#D4D4D4' }}>
        {selectedProviders?.length > 0
          ? 'Sorry, there are no campaigns for the selected companies/offers.'
          : 'Choose companies or offers from the left panel to view the analytics.'}
      </h6>
    </div>
  );
};

export default CampaignList;
